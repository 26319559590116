import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import '../../global1.css';
import DefaultLogo from '../../assests/images/DefaultLogo.png';
import DefaultBack from '../../assests/images/background-img.png';
import LanguageChange from '../LanguageChange/LanguageChange';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Backdrop, IconButton } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Visibility, VisibilityOff} from '@mui/icons-material'
import { Base64 } from 'js-base64';
import DOMPurify from 'dompurify';
// import ReCAPTCHA  from "react-google-recaptcha"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import FloatingLabelDatePicker from '../DateOfBirth/FloatingDatePicker';
import { OktaAuth } from "@okta/okta-auth-js";

//****** LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: 'red',
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: '100%',
    },
  })
);

//******  CHANGING FAVICON DYNAMICALLY  */

function getFaviconEl() {
  return document.getElementById('favicon');
}
function getAppTitle() {
  return document.getElementById('AppTitle');
}

function Register() {
  //*** INITIALIZING DATA */
  const { REACT_APP_API_ENDPOINT} = process.env;
  const [data4, setData4] = useState({});
  const[appBasicDetails,setappBasicDetails] = useState({})
  const [oktaConfigDetails, setoktaConfigDetails] = useState({});
  const [redirectUrl, setredirectUrl] = useState('');
  const [signInRedirect, setSignInRedirect] = useState('')
  const [cancelRedirect, setCancelRedirect] = useState('')
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const [attributeConfigDetails, setAttributeConfigDetails] = useState();
  // PASSWORD COMPLEXITY
  const [passwordComplexity, setPaswordComplexity] = useState({});
  const [OriginAllowed, setOriginAllowed] = useState(false);
  const [OriginDomain, setOriginDomain] = useState(true);
  const [formikValues, setFormikValues] = useState({});
  const [formikErrors,setFormikErrors] = useState({})
  const [regionsObj, setRegionsObj] = useState({})
  const history = useHistory();
  // const captchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha();


  //*** Getting Query params Data */

  const Data = useParams();
  const id = DOMPurify.sanitize(Data.id);
  // const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const LanguageCode = DOMPurify.sanitize(query.get('languageCode'));
  const RedirectURL = DOMPurify.sanitize(query.get('redirectUrl'));

  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = 'lbl_create_account';
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === 'default' &&
    LangData.LanguageData.LanguageData.filter(
      (item) => item.code === CodeLang && item.pageName === pageName
    ).length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === 'default'
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang && item.pageName === pageName
          )[0]?.data
        : {};
  }

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
        setCancelRedirect(res.data.cancelRedirectURL !== "" ? res.data.cancelRedirectURL : res.data.appUrl);
        setSignInRedirect(res.data.signInRedirectURL);
        if(!RedirectURL){
        let data = res.data.appUrl.slice(0, -1);
        const FetchDomain = new URL(data).origin;
        TrustedRedirectUrl(FetchDomain);
        }
      })
      .catch((err) => console.log(err));
  };

  const TrustedRedirectUrl = async (domain) => {
    // setredirectUrl(domain);
    if (OriginDomain) {
      await axios
        .get(`${REACT_APP_API_ENDPOINT}origins/get-origins?domain=${domain}`)
        .then((res) => {
          if (res.data.length > 0) {
            res.data.map((originUrl) => {
              if (
                originUrl.origin === domain &&
                originUrl.status === 'ACTIVE'
              ) {
                setOriginAllowed(true);
              } else {
                setOriginAllowed(false);
              }
              return originUrl;
            });
          } else {
            toast.error(langData?.lbl_create_account_origin_not_allow, {
              position: 'top-center',
            });
            setOriginAllowed(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: 'top-center',
          });
        });
    }
  };

  useEffect(() => {
    if (
      RedirectURL &&
      langData?.lbl_create_account_origin_not_allow !== undefined
    ) {
      setOriginDomain(false);
      try {
          const FetchDomain = new URL(RedirectURL).origin;
          TrustedRedirectUrl(FetchDomain);
      } catch {
        redirect_location();
      }
    } 
    //eslint-disable-next-line
  }, [langData?.lbl_create_account_origin_not_allow]);

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);

  const inputRef = useRef({});

 


  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
        setappBasicDetails(res.data.appBasicDetails)
        setAttributeConfigDetails(res.data.attributeConfigDetails);
        setoktaConfigDetails(res.data.oktaConfigDetails);
        passwordPolicies(res.data.appBasicDetails.oktaGroupID);
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }
  
  useEffect( () => {
    if(attributeConfigDetails){
      attributeConfigDetails?.baseAttributes?.forEach( async(item) => {
        if(item.dropDownURL !== ''){
          await axios.get(`${item.dropDownURL}`)
           .then( res => {
            setRegionsObj(res.data)
           })
           .catch( err => console.log({err}))
        }
      })
    }
  },[attributeConfigDetails])

  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  // const consumerGroupId = data4.appBasicDetails?.oktaGroupID
  const Header_Color = data4.custom_properties?.headerColor;
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const PrivacyPolicy = data4.appBasicDetails?.privacyPolicy;
  const TermsConditions = data4.appBasicDetails?.termsAndConditions;
  const AppTitle = data4.appBasicDetails?.appName;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;




  //**************** DATE RESTRICTION ******* */
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - data4?.appBasicDetails?.minimumAge);



  //******** CREATING USER FUNCTION API  */

  const createUser1 = async (userObj, props) => {
    let reCaptchaTokenCall = ''
    reCaptchaTokenCall = await executeRecaptcha()
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-user/${id}?email=${encodeURIComponent(userObj.email)}&mobileNo=${data4?.appBasicDetails?.enableMobileCheck && userObj.primaryPhone}&reCaptchaToken=${reCaptchaTokenCall}`)
      .then((res) => {
        setLoader(false);
        if (res.data && res.data.responseStatus === 'SUCCESS'){
          if(res.data.errorCode === 'invalid'){
            toast.error( langData.lbl_create_account_phone_email_exists, {
              position: 'top-center', width: 600
            });
          }else if(res.data.errorCode=== 'invalid-email'){
            toast.error(langData["lbl_create_account_email_exist"]?.replace('{0}', userObj.email), {
              position: 'top-center', width: 600
            });
          }else if(res.data.errorCode === 'invalid-mobile'){
            toast.error( langData?.lbl_create_account_phone_exists, {
              position: 'top-center', width: 600
            });
          }
        } else {
          setLoader(true);
          axios
            .post(
              `${REACT_APP_API_ENDPOINT}custom-create-user/${id}?redirect=${
                RedirectURL ? RedirectURL : redirectUrl
              }&languageCode=${LanguageCode ? LanguageCode : 'en'}`,
              {
                ...userObj,
              }
            )
            .then((res) => {
              //******** RESET FORM ***** */
                props.resetForm()
              setLoader(false);
              
            if(res.data.responseStatus === "SUCCESS"){
              // if(appBasicDetails?.enableGoogleCaptcha){
              //   window.grecaptcha.execute()
              // }
              if (res.data.status === 'STAGED' && (oktaConfigDetails.identityFlag === true || oktaConfigDetails?.identityFlagPassword === true || oktaConfigDetails?.identityWithMfaFlag === true)){
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                if (OriginAllowed) {
                    if (RedirectURL) {
                      window.location.href = RedirectURL;
                    } else {
                      window.location.href = redirectUrl;
                    }
                } else {
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: 'top-center',
                  });
                }
              } else if (
                res.data.status === 'SUCCESS' &&
                oktaConfigDetails.mfaFlag === true &&
                formikValues?.isMFA === false
              ) {
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                if (OriginAllowed) {
                    if (RedirectURL) {
                      window.location.href = RedirectURL;
                    } else {
                      window.location.href = redirectUrl;
                    }
                } else {
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: 'top-center',
                  });
                }
              } else if (
                res.data.status === 'ACTIVE' &&
                oktaConfigDetails.authFlag === false &&
                oktaConfigDetails.mfaFlag === false
              ) {
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                let redirect = RedirectURL ? RedirectURL : redirectUrl;
                const EncodedData = `${JSON.stringify(res.data.profile)}`;
                const RedirectData = encodeURI(Base64.encode(EncodedData));
                if (OriginAllowed) {
                    window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
                } else {
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: 'top-center',
                  });
                }
              }else if(
                 res.data.status === "ACTIVE" &&
                 oktaConfigDetails.mfaFlag === true 
              ){
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                let redirect = RedirectURL ? RedirectURL : redirectUrl;
                const redirectObj = {
                   "profile": res.data.profile,
                   "result":res.data.responseStatus,
                   "message": res.data.messgae
                }
                const EncodedData = `${JSON.stringify(redirectObj)}`;
                const RedirectData = encodeURI(Base64.encode(EncodedData));
                if (OriginAllowed) {
                  window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
                } else {
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: 'top-center',
                  });
                }
              }
              else if (
                res.data.status === 'SUCCESS' &&
                oktaConfigDetails.authFlag === true
              ) {
                // toast.success(langData.lbl_create_account_create_success, {
                //   position: 'top-center',
                // });
                if (res.data.sessionToken && res.data._embedded.user.profile) {
                  toast.success(langData.lbl_create_account_create_success, {
                    position: 'top-center',
                  });
                  let redirect = RedirectURL ? RedirectURL : redirectUrl;
                  const redirectObj = {
                    "SessionToken" : res.data.sessionToken,
                    "profile": res.data._embedded.user.profile
                  }
                  const EncodedData = `${JSON.stringify(redirectObj)}`;
                  let RedirectData = encodeURI(Base64.encode(EncodedData));
                  if (OriginAllowed) {
                    window.location.href =  redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
                  } else {
                    toast.error(langData.lbl_create_account_origin_not_allow, {
                      position: 'top-center',
                    });
                  }
                }
              } else if (
                res.data.status === 'MFA_ENROLL' &&
                oktaConfigDetails.authFlag === true
              ) {
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                let redirect = RedirectURL ? RedirectURL : redirectUrl;
                const redirectObj = {
                  "StateToken" : res.data.stateToken,
                  "profile": res.data._embedded.user.profile
                }
                const EncodedData = `${JSON.stringify(redirectObj)}`;
                const RedirectData = encodeURI(Base64.encode(EncodedData));
                if (OriginAllowed) {
                  window.location.href =  redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
                } else {
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: 'top-center',
                  });
                }
                // const user_id = res.data._embedded.user.id;
                // const stateToken = res.data.stateToken;
                // history.push(
                //   `/multifactor/${user_id}/${id}?stateToken=${stateToken}&Relay=decodedrelay&LanguageCode=${LanguageCode}&Show=false`
                // );
              } else if (
                res.data.status === 'MFA_ENROLL'  &&
                oktaConfigDetails.mfaFlag === true &&
                formikValues?.isMFA === true
              ) {
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                if (OriginAllowed) {
                  const user_id = res.data?._embedded?.user?.id;
                  history.push(
                    `/multifactor/${user_id}/${id}?stateToken=${
                      res.data.stateToken
                    }&RedirectURL=${
                      RedirectURL ? RedirectURL : redirectUrl
                    }&Relay=&languageCode=${
                      LanguageCode ? LanguageCode : "en"
                    } &Show=false`
                  );
                }else{
                  toast.error(langData.lbl_create_account_origin_not_allow, {
                    position: "top-center",
                  });
                }
              }else if (
                res.data.status === "MFA_REQUIRED" &&
                oktaConfigDetails.mfaFlag === true &&
                formikValues?.isMFA === true
              ) {
                toast.success(langData.lbl_create_account_create_success, {
                  position: 'top-center',
                });
                if (OriginAllowed) {
                  const stateToken = res.data.stateToken;
                  const userId = res.data._embedded.user.id;
                      const FactorLifeTime =
                        res.data._embedded.policy.rememberDeviceLifetimeInMinutes;
                      localStorage.setItem("FactorLifeTime", FactorLifeTime);
                      history.push(
                        `/passcoderequire/${userId}/${id}?stateToken=${stateToken}&languageCode=${
                          LanguageCode ? LanguageCode : "en"
                        }&Relay=&activate=false`
                      );
                }
              }
            }
            else{
              if(res.data.errorStatus ===  400){
                const Data = JSON.parse(res.data.messgae)
                toast.error(Data.errorCauses[0].errorSummary, {
                  position: 'top-center'
                })
              }else{
                toast.error(res.data.messgae, {position:'top-center'})
              }
            }
            })
            //       setLoader(false);
            //   if(localStorage.getItem("FactorLifeTime")){
            //     setDeviceTokenLifeTime(localStorage.getItem("FactorLifeTime"))
            //   }
            //   const stateToken = res.data.stateToken;
            //   if (res.data.status === "MFA_ENROLL") {
            //   } else if (res.data.status === "MFA_REQUIRED") {
            //     const userId = res.data._embedded.user.id;
            //     const FactorLifeTime =
            //       res.data._embedded.policy.rememberDeviceLifetimeInMinutes;
            //     localStorage.setItem("FactorLifeTime", FactorLifeTime);
            //     history.push(
            //       `/passcode/${userId}/${id}?stateToken=${stateToken}&Relay=${decoded_relay}&LanguageCode=${LanguageCode}&activate=false`
            //     );
            //   } else if (res.data.status === "LOCKED_OUT") {
            //     toast.error(langData.lbl_login_account_lock, {
            //       position: "top-center",
            //     });
            //   } else if (res.data.status === "SUCCESS") {
            //     const sessionToken = res.data.sessionToken;
            //     window.location.href = `https://consumer-pepsico.oktapreview.com/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=https://consumer-pepsico.oktapreview.com${decoded_relay}`;
            //   }
            // })
            .catch((err) => {
              setLoader(false);
              toast.error(err.response.data.errorMessage, {
                position: 'top-center',
              });
            });
        }
      })
      .catch((err) => {
        setLoader(false)
        toast.error(langData.lbl_create_account_try_again, {
          position: 'top-center',
        });
      });
  };

  

  //********* GETTING PASSWORD POLOCIES API */

  const passwordPolicies = async (GroupId) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}policies/get-password-policies`)
      .then((res) => {
        const policyObject = res.data.filter((item) => {
          const groups = item.conditions.people.groups.include;
          if (groups.filter((groupId) => groupId === GroupId).length > 0) {
            return true;
          }
          return false;
        });
        if (policyObject.length > 0) {
          setPaswordComplexity(policyObject[0].settings.password.complexity);
        } else {
          setPaswordComplexity(
            res.data[res.data.length - 1].settings.password.complexity
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };


  //****** CALLING CREATE USER API */

  const onSubmit = async (values, props) => { 
        let object = {
              ...values,
            };
            let newObject = {
              isMFA: values.isMFA,
            };
            attributeConfigDetails?.baseAttributes
              .filter((item) => item?.isSelect)
              .forEach((item) => {
                if (item.type === "array" && !item?.enumList && !item?.oneOf) {
                  object[item.key] = object[item.key].split(",");
                }
              });
            Object.keys(values).forEach((key) => {
              const Obj = attributeConfigDetails?.baseAttributes
                .filter((item) => item?.isSelect)
                .filter(
                  (item) =>
                    item.key === key && item.mappedBy && item.mappedby !== ""
                );
              if (Obj.length > 0) {
                const mappedBy = Obj[0].mappedBy;
                newObject = {
                  ...newObject,
                  [mappedBy]: object[key],
                };
              } else {
                newObject = {
                  ...newObject,
                  [key]: object[key],
                };
              }
            });
            if(appBasicDetails?.enableGoogleCaptcha){
               if (!executeRecaptcha) {
                toast.error(langData?.lbl_create_account_captcha_not_available, {
                  position: 'top-center'
                })
                return;
              }
              const token = await executeRecaptcha("");
              newObject.token = token
            }else{
              newObject.token = ""
            }
            if(appBasicDetails?.enableGoogleCaptcha && newObject?.token){
              createUser1(newObject, props);
            }else{
              if(appBasicDetails?.enableGoogleCaptcha){
                toast.error(langData?.lbl_create_account_verify_captcha,{
                  position:'top-center'
                })
              }else{
                createUser1(newObject,props)
              }
            }
  };

  

  //***** BACK TO HOME PAGE */

  const redirect_sigin = () => {
    if (id && LanguageCode) {
      // const MainRedirect = RedirectURL ? RedirectURL :  redirectUrl
      // window.location.href = MainRedirect + (MainRedirect.includes('?') ? `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` : `?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}`);
      window.location.href = signInRedirect
    }
  };

  const cancel_home = () => {
    if (id && LanguageCode) {
      // window.location.href = RedirectURL ? RedirectURL :  redirectUrl 
      window.location.href = cancelRedirect
    }
  };

  //************************************ Dynamic Related Code start ***********************************/
  
  let initValue = {
    isMFA: false,
  };
  let validityObject = {
    isMFA: yup.bool(),
  };
  attributeConfigDetails?.baseAttributes.filter( item => item?.isSelect).forEach((item) => {
    if (item.key === 'email') {
      initValue = {
        ...initValue,
        [item.key]: '',
        password: '',
        confirmPassword:''
      };
    } else if (item.key === 'login') {
    } else if (
      item.type === 'array' &&
      item?.enumList &&
      item?.oneOf &&
      item?.enumList.length > 0 &&
      item?.oneOf.length > 0
    ) {
      initValue = {
        ...initValue,
        [item.key]: [],
      };
    } else if (item.type === 'array') {
      initValue = {
        ...initValue,
        [item.key]: '',
      };
    }else if(item.type === 'boolean'){
      initValue = {
        ...initValue,
        [item.key]: false,
      };
    }
    else {
      initValue = {
        ...initValue,
        [item.key]: item?.oneOf?.length === 1 ? item?.oneOf[0]?.constVal : '' ,

      };
    }

    if (item.key === 'login') {
    } else if (item.key === 'email') {
      if(id === '163142638239744' || id === '915041708670976' || id === "824955038072832"){
        validityObject[item.key] = yup
        .string()
        .required(langData?.lbl_create_account_email_required)
        .email(langData?.lbl_create_account_email_invalid)
        .test('EmailValidation', "We're sorry. you can not create account using email aliases", (val) => {
            if(val && val.length > 0){
              let regex = /^[^+&#$%*^]+@[^\s@]+\.[^\s@]+$/g;
              let Data = val.match(regex);
              if (Data) {
                return true;
              } else {
                return false;
              }
            }
        });
      }else{
        validityObject[item.key] = yup
        .string()
        .required(langData?.lbl_create_account_email_required)
        .email(langData?.lbl_create_account_email_invalid)
      }
    } else if (
      item.type === 'array' &&
      item?.enumList &&
      item?.oneOf &&
      item?.enumList.length > 0 &&
      item?.oneOf.length > 0
    ) {
      validityObject[item.key] = yup
        .array()
        .of(yup.string().required(langData?.lbl_create_account_field_required));
    } else if (item.type === 'array' && item.itemType === 'number') {
      validityObject[item.key] = yup
        .string()
        .test(
          'required',
          langData?.lbl_create_account_comma_integer,
          (value) => {
            if (value) {
              let regex = /^[0-9]+(,?[0-9]+)*?$/g;
              let Data = value.match(regex);
              if (Data) {
                return true;
              } else {
                return false;
              }
            }
          }
        );
    }
    else if(item.mappedBy === 'confirmPhone' && item.type === 'string'){
      validityObject[item.key] = yup
      .string()
      .required(getErrorMsg(item.key, 1, item.title))
      .oneOf(
        [yup.ref('phoneNumber'), null], `${langData?.lbl_create_account_phone_not_match}`
      )
    }
    else if (item.type === 'number') {
      validityObject[item.key] = yup
        .string()
        .test('required', langData?.lbl_create_account_number_only, (value) => {
          if (value) {
            let regex = /^[0-9]*$/g;
            let Data =  value.match(regex);
            if (Data) {
              return true;
            } else {
              return false;
            }
          }
        });
    } else if (item.type === 'array' && item.itemType === 'string') {
      validityObject[item.key] = yup
        .string()
        .test('required', langData?.lbl_create_account_comma_string, (value) => {
          if (value) {
            let regex = /^[a-zA-Z]+(,?[a-zA-Z]+)*?$/g;
            let Data = value.match(regex);
            if (Data) {
              return true;
            } else {
              return false;
            }
          }
        });
    }else if(item.type === 'boolean'){
      validityObject[item.key] = yup
      .boolean()
      .test('required', getErrorMsg(item.key, 1, item.title), (value) => {
        if (item?.required) {
          if (value && value === true) {
            return true
          } else {
            return false;
          }
        }else{
          return true
        }
      })
    } else {
      validityObject[item.key] = yup
        .string()
        .test('required', getErrorMsg(item.key, 1, item.title), (value) => {
          if (item?.required) {
            if (value) {
            } else {
              return false;
            }
          }
          return true;
        })
        .test(
          'minLength',
          getErrorMsg(item.minLength, 2, item.title),
          (value) => {
            if (value && item?.minLength && item.minLength > value.length) {
              return false;
            }
            return true;
          }
        )
        .test(
          'maxLength',
          getErrorMsg(item.maxLength, 3, item.title),
          (value) => {
            if (value && item?.maxLength && item.maxLength < value.length) {
              return false;
            }
            return true;
          }
        );
    }
    if(item.regexValidator && item.required){
      validityObject[item.key] = yup
       .string()
       .required(getErrorMsg(item.key, 1, item.title))
       .test( item.key + 'validation', getErrorMsg(item.key, 4, item.title),
        (value) => {
          if(value){
            const regex = new RegExp(item.regexValidator);
            // let regex = /[\d]{1,2}\.[\d]{3}\.[\d]{3}-[\dKk]{1}/
            let Data = value?.match(regex)
            if(Data){
              return true
            }else{
              return false
            }
          }else{
            return true
          }
        }
       )
      
    }
    else if(item.regexValidator){
      validityObject[item.key] = yup
       .string()
       .test('validation', getErrorMsg(item.key, 4, item.title),
        (value) => {
          if(value){
            const regex = new RegExp(item.regexValidator);
            // let regex = /[\d]{1,2}\.[\d]{3}\.[\d]{3}-[\dKk]{1}/
            let Data = value?.match(regex)
            if(Data){
              return true
            }else{
              return false
            }
          }else{
            return true
          }
        }
       )
      
    }
  });
  if (!(oktaConfigDetails.identityFlag || oktaConfigDetails.identityWithMfaFlag)) {
    validityObject['password'] = yup
      .string()
      .required(langData?.lbl_create_account_password_required)
      .min(
        passwordComplexity.minLength,
        langData &&
          langData['lbl_create_account_min_password_length']?.replace(
            '{0}',
            passwordComplexity.minLength
          )
      )
      .test(
        'minNumber',
        langData?.lbl_create_account_one_number_required,
        (value) => {
          if (value && passwordComplexity.minNumber > 0) {
            const regex = /\d/g;
            let number = value.match(regex);
            if (number && number.length >= passwordComplexity.minNumber) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        'minLowercase',
        langData?.lbl_create_account_one_lowercase_required,
        (value) => {
          if (value && passwordComplexity.minLowerCase > 0) {
            const regex = /[a-z]/g;
            let text = value.match(regex);
            if (text && text.length >= passwordComplexity.minLowerCase) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        'minUppercase',
        langData?.lbl_create_account_one_uppercase_required,
        (value) => {
          if (value && passwordComplexity.minUpperCase > 0) {
            let text = value.match(/[A-Z]/g);
            if (text && text.length >= passwordComplexity.minUpperCase) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        'minSymbol',
        langData?.lbl_create_account_one_symbol_required,
        (value) => {
          if (value && passwordComplexity.minSymbol > 0) {
            let text = value.match(/[^A-Z0-9a-z]/g);
            if (text && text.length >= passwordComplexity.minSymbol) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        'no_Username',
        langData?.lbl_create_account_no_username,
        (value) => {
          let Email = formikValues?.email;
          if (Email.includes('@')) {
            Email = Email.split('@')[0];
          }
          if (value && passwordComplexity.excludeUsername) {
            if (Email !== '') {
              if (
                value.toLowerCase().includes(Email.toLowerCase()) ||
                value.toLowerCase().includes(id)
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .test(
        'no_Firstname',
        langData?.lbl_create_account_no_first_name,
        (value) => {
          let Firstname = formikValues?.firstName;
          if (
            value &&
            passwordComplexity?.excludeAttributes?.length > 0 &&
            passwordComplexity.excludeAttributes.indexOf('firstName') !== -1
          ) {
            if (formikValues?.firstName !== '') {
              if (value.toLowerCase().includes(Firstname.toLowerCase())) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .test(
        'no_Lastname',
        langData?.lbl_create_account_no_last_name,
        (value) => {
          if (
            value &&
            passwordComplexity?.excludeAttributes?.length > 0 &&
            passwordComplexity.excludeAttributes.indexOf('lastName') !== -1
          ) {
            if (formikValues?.lastName !== '') {
              if (
                value
                  .toLowerCase()
                  .includes(formikValues?.lastName.toLowerCase())
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
          return true;
        }
      );
      validityObject['confirmPassword'] =yup
      .string()
      .required(langData?.lbl_create_account_confirm_password_required)
      .oneOf(
        [yup.ref("password"), null],
        langData?.lbl_create_account_password_must_match
      )
  }

  const validitySchema = yup.object(validityObject);

  // TYPE =  1 For Required
  // TYPE =  2 for Minimum
  // Type = 3 for Maximum
  function getErrorMsg(key, type, title) {
    if (type === 1) {
      return (
        langData &&
        langData['lbl_create_account_required_argument']?.replace(
          '{0}',
          langData['lbl_create_account_' + key]
            ? langData['lbl_create_account_' + key]
            : title
        )
      );
    } else if (type === 2) {
      return (
        langData &&
        langData['lbl_create_account_minimum_argument']?.replace('{0}', key)
      );
    } else if (type === 3) {
      return (
        langData &&
        langData['lbl_create_account_maximum_argument']?.replace('{0}', key)
      );
    } else if( type === 4){
       return(
        langData &&
        langData['lbl_create_account_not_valid_argument']?.replace(
          '{0}',
          langData['lbl_create_account_' + key]
            ? langData['lbl_create_account_' + key]
            : title
        )
       )
    }
  }

  //************************************ Dynamic Related Code End **************************************/

  //*********    FAVICON DYNAMIC CHANGE */

  const handlefavicon = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (logo) {
      favicon.href = logo;
    }
  };
  const handleAppTitle = () => {
    const Title = getAppTitle();
    if (AppTitle) {
      Title.innerText = AppTitle;
    }
  };

  useEffect(() => {
    handlefavicon();
    handleAppTitle();
    //eslint-disable-next-line
  }, [logo]);

 function captchaVisibility(){
 document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
}

const scrollToField = (fieldName) => {
  if (inputRef.current[fieldName]) {
     inputRef.current[fieldName].focus()
  }
};

const onAuthRequired = () => {
  history.push("/home");
};

const RouteGoogle = async (IDP) => {
  const oktaAuthSso = new OktaAuth({
    issuer: data4.oktaConfigDetails?.issuer,
    clientId: data4.oktaConfigDetails?.clientID,
    redirectUri: data4.oktaConfigDetails?.redirectURI,
    onAuthRequired: onAuthRequired,
    pkce: true,
  });
let socialIDP = ''
if(IDP === 'google'){
   socialIDP = data4.oktaConfigDetails?.googleIDP
}else if(IDP === 'facebook'){
  socialIDP = data4.oktaConfigDetails?.facebookIDP
}else if(IDP === 'apple'){
  socialIDP = data4.oktaConfigDetails?.appleIDP
}
  oktaAuthSso.signInWithRedirect({
    idp: socialIDP,
    responseType: "code",
    originalUri: "/",
    scopes: ["openid", "offline_access"],
    redirectUri: data4.oktaConfigDetails?.redirectURI,
  });
};


  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: '#fff' }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
            {
              AppTitle && AppTitle.toLowerCase().split(' ')[0] === "latam" &&
              <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W692TMMQ');
          `}
              </script>
            } 
          </Helmet>
          {/* Google Tag Manager (noscript) */}
          {
            AppTitle && AppTitle.toLowerCase().split(' ')[0] === "latam" &&
          <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-W692TMMQ"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            title='Latam Iframe'
          ></iframe>
          </noscript>
          }
          
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={id}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : 'en'}
            />
            {loader ? (
              <Backdrop className={classes.root} sx={{ color: '#fff' }} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}

            <div
              className="main-bg sign-up-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo ? logo : DefaultLogo} alt="Pepsi Micdrop Logo" />
                  </div>
                  <h1
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {langData?.lbl_create_account}
                  </h1>
                </div>
                {langData?.lbl_create_account_info_message &&
                  <p className="text-center sub-title">
                  {langData["lbl_create_account_info_message"]}
                  </p>
                }
                <p className="before-sign-in">
                  {langData?.lbl_create_account_text_two}
                  <button
                    style={{
                      textDecoration: 'underline',
                      border: 'none',
                      background: '#fff',
                      color: '#000',
                      fontWeight: 'bolder',
                    }}
                    onClick={redirect_sigin}
                  >
                    {langData?.lbl_create_account_sign_in}
                  </button>
                </p>
                <div className="form-group text-center mb-0">
                          {
                              (data4.oktaConfigDetails?.enableSocialLogin && data4?.appBasicDetails?.isSocialLoginOnCreate) &&
                              (
                               <>
                               {
                                data4.oktaConfigDetails?.googleIDP && 
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    style={{ background: SignIn_button_color }}
                                    id='google-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('google')}
                                    aria-label={langData?.lbl_login_google_sign_in}
                                  >
                                  SIGN UP VIA GOOGLE
                                  </button>
                               }
                               {
                                data4.oktaConfigDetails?.facebookIDP &&
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    id='facebook-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('facebook')}
                                    aria-label="Login Via Facebook"
                                  >
                                  Login Via Facebook
                                  </button>
                               }
                               {
                                data4.oktaConfigDetails?.appleIDP &&
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    style={{ background: SignIn_button_color }}
                                    id='apple-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('apple')}
                                    aria-label='Login Via Apple'
                                  >
                                    Login Via Apple
                                  </button>
                               }
                               </> 
                              
                              )
                              
                          }
                      </div>
                      {
                        data4.appBasicDetails?.isSocialLoginOnCreate &&
                      <div className="form-group">
                            <div className="or" aria-label="OR">
                              <span>OR</span>
                            </div>
                      </div>
                      }
                <div className="wrapper-inner">
                  {initValue ? (
                    <Formik
                      enableReinitialize
                      initialValues={initValue}
                      onSubmit={onSubmit}
                      validationSchema={validitySchema}
                      innerRef={(formikActions) => {
                        formikActions? setFormikValues(formikActions.values): setFormikValues({})
                        formikActions ? setFormikErrors(formikActions.errors) : setFormikErrors({})
                      }
                      }
                      
                    >
                      {(props) => (
                        <Form className="mb-0">
                          {attributeConfigDetails
                            ? attributeConfigDetails?.baseAttributes.filter( item => item?.isSelect).map(
                                (e, i) => {
                                  if (e.key === 'login') {
                                    return null;
                                  } else if (e.key === 'email') {
                                    return (
                                      <div key={i}>
                                        <Field key={i}>
                                          {() => (
                                            <div
                                              className={
                                                props.touched[e.key] &&
                                                props.errors[e.key]
                                                  ? 'sign-up-sec form-group has-danger'
                                                  : 'sign-up-sec form-group'
                                              }
                                            >
                                              <input
                                                type="text"
                                                className="custom-input"
                                                name={e.key}
                                                id={e.key}
                                                value={props.values[e.key]}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                style={{ color: Input_color }}
                                                required
                                                aria-label={e.key}
                                                ref={(el) => (inputRef.current[e.key] = el)}
                                              />
                                              <label className="custom-label" htmlFor={e.key}>
                                                {(langData &&
                                                langData[
                                                  'lbl_create_account_' + e.key
                                                ]
                                                  ? langData[
                                                      'lbl_create_account_' +
                                                        e.key
                                                    ]
                                                  : e.title) +
                                                  (e?.required ? '*' : '')}
                                              </label>
                                              {props.touched[e.key] &&
                                              props.errors[e.key] ? (
                                                <div className="error-msg" aria-label={props.errors[e.key]}>
                                                  {props.errors[e.key]}
                                                </div>
                                              ) : null}
                                            </div>
                                          )}
                                        </Field>
                                        { !(oktaConfigDetails.identityFlag || oktaConfigDetails.identityWithMfaFlag) ? (
                                          <>
                                          <Field key="password">
                                            {() => (
                                              <>
                                              <div
                                                className={
                                                  props.touched.password &&
                                                  props.errors.password
                                                    ? 'sign-up-sec form-group has-danger'
                                                    : 'sign-up-sec form-group'
                                                }
                                              >
                                                <input
                                                  className="custom-input"
                                                  type={ showPassword ? "text" : "password"}
                                                  name="password"
                                                  id="password"
                                                  value={props.values.password}
                                                  onChange={props.handleChange}
                                                  onBlur={props.handleBlur}
                                                  style={{ color: Input_color }}
                                                  required
                                                  aria-label='password'
                                                  ref={(el) => (inputRef.current["password"] = el)}

                                                />
                                                <label className="custom-label" htmlFor='password'>
                                                  {langData?.lbl_create_account_password +
                                                    (e?.required ? '*' : '')}
                                                </label>
                                                <IconButton
                                                className="sign-up-sec eyeIcon"
                                                onClick={ () =>{
                                                  setShowPassword( previousState => !previousState)
                                                }}
                                                aria-label="Show/hide password text"
                                                >
                                                 {showPassword ? <Visibility /> : <VisibilityOff /> }
                                                </IconButton>
                                                {props.touched.password &&
                                                props.errors.password ? (
                                                  <div className="error-msg" aria-label={props.errors.password}>
                                                    {props.errors.password}
                                                  </div>
                                                ) : null}
                                              </div>
                                              </>
                                              
                                            )}
                                          </Field>
                                          <Field key="confirmPassword">
                                            {() => (
                                              <>
                                              <div
                                                className={
                                                  props.touched.confirmPassword &&
                                                  props.errors.confirmPassword
                                                    ? 'sign-up-sec form-group has-danger'
                                                    : 'sign-up-sec form-group'
                                                }
                                              >
                                                <input
                                                  className="custom-input"
                                                  type="password"
                                                  name="confirmPassword"
                                                  id='confirmPassword'
                                                  value={props.values.confirmPassword}
                                                  onChange={props.handleChange}
                                                  onBlur={props.handleBlur}
                                                  style={{ color: Input_color }}
                                                  required
                                                  aria-label='confirmPassword'
                                                  ref={(el) => (inputRef.current["confirmPassword"] = el)}

                                                />
                                                <label className="custom-label" htmlFor="confirmPassword">
                                                  {langData?.lbl_create_account_confirm_password +
                                                    (e?.required ? '*' : '')}
                                                </label>
                                                {props.touched.confirmPassword &&
                                                props.errors.confirmPassword ? (
                                                  <div className="error-msg" aria-label={props.errors.confirmPassword}>
                                                    {props.errors.confirmPassword}
                                                  </div>
                                                ) : null}
                                              </div>
                                              
                                              {!oktaConfigDetails.identityFlag ? 
                                              (
                                                          <div className="pwd-requirement">
                                                            <p className="font-weight-bold">
                                                              {langData?.lbl_create_account_password_require}
                                                            </p>
                                                            <ul className="pl-4 mb-0">
                                                              {passwordComplexity.minLength > 0 ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_minimum_argument?.replace(
                                                                    '{0}',
                                                                    passwordComplexity.minLength
                                                                  )}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.minLowerCase > 0 ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_lower_char}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.minUpperCase > 0 ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_upper_char}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.minNumber > 0 ? (
                                                                <li>{langData?.lbl_create_account_number}</li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.minSymbol > 0 ? (
                                                                <li>{langData?.lbl_create_account_symbol}</li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.excludeUsername ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_no_username}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.excludeAttributes &&
                                                              passwordComplexity.excludeAttributes.filter(
                                                                (item) => item === 'firstName'
                                                              ).length > 0 ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_no_first_name}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {passwordComplexity.excludeAttributes &&
                                                              passwordComplexity.excludeAttributes.filter(
                                                                (item) => item === 'lastName'
                                                              ).length > 0 ? (
                                                                <li>
                                                                  {langData?.lbl_create_account_no_last_name}
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </ul>
                                                          </div>
                                                        ) : null}
                                              </>
                                              
                                            )}
                                          </Field>
                                          </>
                                          
                                        ) : null}
                                      </div>
                                    );
                                  } else if (e.key === 'userAddress') {
                                    return (
                                      <Field key={i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                          >
                                            <textarea
                                              type="text"
                                              className="custom-input"
                                              name={e.key}
                                              id={e.key}
                                              value={props.values[e.key]}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              style={{ color: Input_color }}
                                              required
                                              aria-label={e.key}
                                              ref={(el) => (inputRef.current[e.key] = el)}
                                            />
                                            <label className="custom-label" htmlFor={e.key}>
                                              {(langData &&
                                              langData[
                                                'lbl_create_account_' + e.key
                                              ]
                                                ? langData[
                                                    'lbl_create_account_' +
                                                      e.key
                                                  ]
                                                : e.title) +
                                                (e?.required ? '*' : '')}
                                            </label>
                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg" aria-label={props.errors[e.key]}>
                                                {props.errors[e.key]}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  } else if (
                                    e.type === 'array' &&
                                    e?.enumList &&
                                    e?.oneOf &&
                                    e?.enumList.length > 0 &&
                                    e?.oneOf.length > 0
                                  ) {
                                    return (
                                      <Field key={'custom_' + i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                          >
                                            <select
                                              multiple={true}
                                              className="custom-input"
                                              name={e.key}
                                              id={e.key}
                                              value={props.values[e.key]}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              style={{ color: Input_color }}
                                              required
                                              aria-label={e.key}
                                              ref={(el) => (inputRef.current[e.key] = el)}
                                            >
                                              <option value="">
                                                Please Select
                                              </option>
                                              {e.oneOf.map((option, i) => {
                                                return (
                                                  <option
                                                    key={option.constVal}
                                                    value={option.constVal}
                                                  >
                                                    {option.title}
                                                  </option>
                                                );
                                              })}
                                            </select>

                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg"  aria-label={props.errors[e.key]}>
                                                {props.errors[e.key]}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  } else if (
                                    e?.enumList &&
                                    e?.oneOf &&
                                    e?.enumList.length > 0 &&
                                    e?.oneOf.length > 0
                                  ) {
                                    return (
                                      <Field key={'custom_' + i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                          >
                                            <select
                                              className="custom-input"
                                              name={e.key}
                                              id={e.key}
                                              value={props.values[e.key]}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              style={{ color: Input_color }}
                                              required
                                              aria-label={e.key}
                                              ref={(el) => (inputRef.current[e.key] = el)}
                                            >
                                              {/* <option value="" disabled></option>
                                              {e.oneOf.map((option, i) => {
                                                return (
                                                  <option
                                                    key={option.constVal}
                                                    value={option.constVal}
                                                  >
                                                    {option.title}
                                                  </option>
                                                );
                                              })} */}
                                              {
                                               (e?.dropDownURL !== '' ) ?
                                               e.key === 'comuna' && regionsObj   ?
                                              <>
                                               <option value="" disabled></option>
                                             {
                                             props.values['regions'] &&  Object.keys(regionsObj[e.key][props?.values?.regions])?.map( item => {
                                                 return (
                                                   <option
                                                     key={item}
                                                     value={regionsObj[e.key][props?.values?.regions][item]}
                                                   >
                                                     {item}
                                                   </option>
                                                 );
                                               })
                                             }
                                              </>
                                              :
                                              <>
                                              <option value="" disabled></option>
                                                {
                                                regionsObj[e.key] && Object.keys(regionsObj[e.key])?.map( item => {
                                                    return (
                                                      <option
                                                        key={item}
                                                        value={regionsObj[e.key][item]}
                                                      >
                                                        {item}
                                                      </option>
                                                    );
                                                  })
                                                }
                                              </> 
                                                : 
                                                <>
                                                <option value="" disabled></option>
                                              {e.oneOf.map((option, i) => {
                                                return (
                                                  <option
                                                    key={option.constVal}
                                                    value={option.constVal}
                                                    
                                                  >
                                                    {option.title}
                                                  </option>
                                                );
                                              })}
                                                </>
                                              }
                                            </select>
                                            <label className='custom-label' htmlFor={e.key}>{(langData &&
                                              langData[
                                                'lbl_create_account_' + e.key
                                              ]
                                                ? langData[
                                                    'lbl_create_account_' +
                                                      e.key
                                                  ]
                                                : e.title) +
                                                (e?.required ? '*' : '')}</label>
                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg" aria-label={props.errors[e.key]}>
                                                {props.errors[e.key]}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  } else if (e?.type === 'boolean') {
                                    return (
                                      <Field key={i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                            
                                          >
                                              <label className="custom-checkbox" htmlFor={e.key}>
                                              { 
                                              e.mappedBy === 'termsandconditions' || e.mappedBy === 'optinB' ?
                                                  <p className="privacy-terms">
                                                      {langData?.lbl_create_account_text_three}{' '}
                                                      {
                                                        PrivacyPolicy !== '' &&
                                                        <Link
                                                        to={{ pathname: PrivacyPolicy }}
                                                        target="_blank"
                                                        aria-label={langData?.lbl_create_account_privacy_policy + ' opens in new nab'}
                                                      >
                                                        {langData?.lbl_create_account_privacy_policy} <OpenInNewIcon />
                                                      </Link>
                                                      }
                                                      
                                                      {
                                                        TermsConditions !== '' &&
                                                        <>
                                                        {langData?.lbl_create_account_and}
                                                          <Link
                                                            to={{ pathname: TermsConditions }}
                                                            target="_blank"
                                                            aria-label={langData?.lbl_create_account_temrs_conditions + ' opens in new nab'}
                                                          >
                                                            {langData?.lbl_create_account_temrs_conditions} <OpenInNewIcon />
                                                          </Link> 
                                                        </>
                                                      }
                                                      {langData?.lbl_create_account_text_four}
                                                  </p> 
                                                : e.mappedBy === 'termsAndConditions_Seperate' ?
                                                <p className="privacy-terms">
                                                {
                                                  TermsConditions !== '' &&
                                                  <>
                                                  {langData?.lbl_create_account_agree_our}
                                                    <Link
                                                      to={{ pathname: TermsConditions }}
                                                      target="_blank"
                                                      aria-label={langData?.lbl_create_account_temrs_conditions + ' opens in new nab'}
                                                    >
                                                      { ' '+ langData?.lbl_create_account_temrs_conditions} <OpenInNewIcon />
                                                    </Link> 
                                                  </>
                                                }
                                                {langData?.lbl_create_account_text_four}
                                            </p> : e.mappedBy === 'privacyPolicy_Seperate' ?
                                              <p className="privacy-terms">
                                                  {langData?.lbl_create_account_text_three}{' '}
                                                  {
                                                    PrivacyPolicy !== '' &&
                                                    <Link
                                                    to={{ pathname: PrivacyPolicy }}
                                                    target="_blank"
                                                    aria-label={langData?.lbl_create_account_privacy_policy + ' opens in new nab'}
                                                  >
                                                    {langData?.lbl_create_account_privacy_policy} <OpenInNewIcon />
                                                  </Link>
                                                  }
                                                  {langData?.lbl_create_account_text_four}
                                              </p> 
                                            : langData['lbl_create_account_' + e.key] ? langData['lbl_create_account_' + e.key] : e.title
                                                 +
                                                (e?.required ? '*' : '')}
                                              <input
                                                type="checkbox"
                                                name={e.key}
                                                id={e.key}
                                                value={props.values[e.key]}
                                                checked={props.values[e.key]}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                style={{ color: Input_color }}
                                                required
                                                aria-label={e.key}
                                                ref={(el) => (inputRef.current[e.key] = el)}
                                              />
                                              <span className="checkmark"></span>
                                              </label>
                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg" aria-label={langData?.lbl_create_account_general_required}>
                                                {langData?.lbl_create_account_general_required}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  } else if (e?.type === 'array') {
                                    return (
                                      <Field key={i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                          >
                                            <input
                                              type="text"
                                              className="custom-input"
                                              name={e.key}
                                              id={e.key}
                                              value={props.values[e.key]}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              style={{ color: Input_color }}
                                              required
                                              aria-label={e.key}
                                              ref={(el) => (inputRef.current[e.key] = el)}
                                            />
                                            <label className="custom-label" htmlFor={e.key}>
                                              {(langData &&
                                              langData[
                                                'lbl_create_account_' + e.key
                                              ]
                                                ? langData[
                                                    'lbl_create_account_' +
                                                      e.key
                                                  ]
                                                : "null") +
                                                (e?.required ? '*' : '')}
                                            </label>
                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg" aria-label={props.errors[e.key]}>
                                                {props.errors[e.key]}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  }else if(e?.key === 'dateOfBirth'){
                                    return (
                                      <FloatingLabelDatePicker 
                                          name={e.key}
                                          label="Date Of Birth"
                                          keyname={e.key}
                                          langdata={langData}
                                          entiredata={e}
                                          entireprops={props}
                                          mindate={minDate}
                                          key={e.key}
                                          langname={PageName}
                                          langcode={LanguageCode}
                                      />
                                    );
                                  }else {
                                    return (
                                      <Field key={i}>
                                        {() => (
                                          <div
                                            className={
                                              props.touched[e.key] &&
                                              props.errors[e.key]
                                                ? 'sign-up-sec form-group has-danger'
                                                : 'sign-up-sec form-group'
                                            }
                                          >
                                            <input
                                              type="text"
                                              className="custom-input"
                                              name={e.key}
                                              id={e.key}
                                              value={props.values[e.key]}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              style={{ color: Input_color }}
                                              required
                                              aria-label={e.key}
                                              ref={(el) => (inputRef.current[e.key] = el)}
                                            />
                                            <label className="custom-label" htmlFor={e.key}>
                                              {(langData &&
                                              langData[
                                                'lbl_create_account_' + e.key
                                              ]
                                                ? langData[
                                                    'lbl_create_account_' +
                                                      e.key
                                                  ]
                                                : e.title) +
                                                (e?.required ? '*' : '')}
                                            </label>
                                            {props.touched[e.key] &&
                                            props.errors[e.key] ? (
                                              <div className="error-msg" aria-label={props.errors[e.key]}>
                                                {props.errors[e.key]}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Field>
                                    );
                                  }
                                }
                              )
                            : null}
                          {oktaConfigDetails.mfaFlag ? (
                            <Field key={'mfaFlag'}>
                              {() => (
                                <div
                                  className={
                                    props.touched.isMFA && props.errors.isMFA
                                      ? 'sign-up-sec form-group has-danger'
                                      : 'sign-up-sec form-group'
                                  }
                                >
                                  <label className="custom-checkbox">
                                    Enroll for MFA
                                    <input
                                      type="checkbox"
                                      name="isMFA"
                                      id="isMFA"
                                      value={props.values.isMFA}
                                      checked={props.values.isMFA}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      style={{ color: Input_color }}
                                      required
                                      aria-label="MFA"
                                      ref={(el) => (inputRef.current["mfaFlag"] = el)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>

                                  {props.touched.isMFA && props.errors.isMFA ? (
                                    <div className="error-msg" aria-label={props.errors.isMFA}>
                                      {props.errors.isMFA}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Field>
                          ) : null}
                          {/* {
                             appBasicDetails?.enableGoogleCaptcha && appBasicDetails?.enableGoogleCaptcha ?
                             <ReCAPTCHA 
                                sitekey={REACT_APP_CAPTCHA_SITE_KEY} 
                                ref={captchaRef}
                                size='normal'
                                badge='inline'
                                className='form-group d-flex align-items-center justify-content-center'
                              /> 
                              :
                              null
                          }    */}
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block "
                              type="button"
                              disabled={!OriginAllowed}
                              onClick={ () => {
                                if( Object.keys(formikErrors).length === 0 && props.values['firstName'] === ''){
                                  document.getElementById('firstName').scrollIntoView({ behavior: 'smooth', block: 'center' });
                                }
                                  if (Object.keys(props.errors).length > 0) {
                                    // Find the first field with an error
                                    props.submitForm()
                                    let formikErrors1 = Object.keys(props.errors)
                                    let attributeOrder = attributeConfigDetails.baseAttributes.map(item => {
                                      if(item?.isSelect){
                                        for(let i=0;i<formikErrors1.length;i++){
                                          if(item.key === formikErrors1[i]){
                                            return formikErrors1[i]
                                          }
                                        }
                                      }
                                      return null
                                    }).filter(item => item !== null)
                                    const firstErrorField = attributeOrder[0];
                                    scrollToField(firstErrorField)
                                    // inputRef.current[firstErrorField].scrollIntoView({ behavior: 'smooth', block: 'center' });
                                  }else{
                                  props.handleSubmit()
                                }
                              }}
                              style={{
                                backgroundColor: SignIn_button_backgroundColor,
                                color: Input_color,
                              }}
                              aria-label={langData?.lbl_create_account}
                            >
                              {langData?.lbl_create_account}
                            </button>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow-brd btn-block"
                              style={{
                                color: SignIn_button_color,
                                background: SecondaryButtonColor,
                              }}
                              type="button"
                              onClick={cancel_home}
                              aria-label={langData?.lbl_create_account_cancel}
                            >
                              {langData?.lbl_create_account_cancel}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          
        </>
      )}
       
    </div>
  );
}

export default Register;
